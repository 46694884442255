"use client";

import { getGoogleOAuthUrlAction } from "@/actions/google/getGoogleOAuthUrl";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import Image from "next/image";

export function GoogleOAuthButton({ purpose }: { purpose: "login" | "signup" }) {
	const { toast } = useToast();

	return (
		<Button
			className="w-full"
			variant={"outline"}
			type="button"
			onClick={async () => {
				const googleOauthUrl = await getGoogleOAuthUrlAction();
				if (googleOauthUrl) {
					window.location.href = googleOauthUrl.url;
				} else {
					toast({
						title: "An error occurred while trying to log in with Google.",
						variant: "destructive",
					});
				}
			}}
		>
			<Image src="/images/google-logo.png" alt="Google" className="mr-1" width={30} height={30} />
			{purpose === "login" ? "Log In" : "Sign Up"} with Google
		</Button>
	);
}
