"use client";

import { loginUserAction } from "@/actions/auth/loginUser";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { loginUserSchema } from "@/schemas/auth/loginUser";
import type { LoginUserPayload } from "@/types/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAction } from "next-safe-action/hooks";
import { useForm } from "react-hook-form";
import { GoogleOAuthButton } from "../common/Button/GoogleOAuthButton";
import { PasswordInput } from "../common/Forms/PasswordInput";

const LoginForm = () => {
	const form = useForm({
		resolver: zodResolver(loginUserSchema),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const { toast } = useToast();

	const { execute, isExecuting } = useAction(loginUserAction, {
		onSuccess() {
			toast({
				title: "You have successfully logged in.",
				variant: "successful",
			});
		},
		onError() {
			toast({
				title: "Login failed",
				variant: "destructive",
			});
		},
	});

	const onSubmit = async (values: LoginUserPayload) => {
		execute(values);
	};

	return (
		<Form {...form}>
			<form className="w-3/4 space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Email Address</FormLabel>
							<FormControl>
								<Input type="email" placeholder="Enter your email" {...field} disabled={isExecuting} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField control={form.control} name="password" render={({ field }) => <PasswordInput field={field} disabled={isExecuting} />} />
				<div className="flex flex-row justify-between gap-2">
					<Button type="submit" className="w-full" disabled={isExecuting || !form.formState.isValid || !form.formState.isDirty}>
						Log In
					</Button>
					<GoogleOAuthButton purpose="login" />
				</div>
			</form>
		</Form>
	);
};

export default LoginForm;
